import React from "react";
import TrackedLink from "./utilities/TrackedLink";
import Section from "./Section";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="bg-brand-beige-40 text-brand-gray font-normal">
        <Section classNameInnerContainer="text-center ">
          <div className="flex text-xs sm:text-base justify-center space-x-3">
            <TrackedLink
              className="hover:underline"
              href="https://docs.achtsamkeitsakademie.de/impressum"
            >
              Impressum
            </TrackedLink>
            <span>/</span>
            <TrackedLink
              className="hover:underline"
              href="https://docs.achtsamkeitsakademie.de/datenschutzerklaerung"
            >
              Datenschutz
            </TrackedLink>
          </div>
          <p className="text-xs sm:text-base">
            © {new Date().getFullYear()} Achtsamkeitsakademie GmbH - Alle Rechte
            vorbehalten
          </p>
        </Section>
      </footer>
    );
  }
};

export default Footer;
