import React from "react";

import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";

const Header = ({ imageObject, className }) => {
  return (
    <header className={`${className} flex justify-center py-1 sm:py-3 px-5 font-normal h-14 sm:h-20`}>
      <PreviewCompatibleImage
        imageObject={imageObject}
        loading="eager"
        className="h-full max-w-screen-lg"
        objectFit="contain"
      />
    </header>
  );
};

export default Header;