import React, { useEffect } from "react";

import Header from "./Header";
import Footer from "./Footer";

import Seo from "./Seo";
import CookieConsent from "./CookieConsent";


import "../styles/tailwind.css";

import "@fontsource/montserrat/latin-300.css";
import "@fontsource/montserrat/latin-500.css";
import "@fontsource/montserrat/latin-600.css";

const Layout = ({
  imageObject,
  seoObject,
  children,
  className = "",
  isWithHeader = true,
}) => {
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "page_rendered",
        branch: document.getElementById("#splittestBranchData")?.value,
      });
    }
  }, []);

  return (
    <>
      {seoObject && <Seo seoObject={seoObject} />}
      {isWithHeader && imageObject && <Header imageObject={imageObject} />}
      <main className={`text-brand-black font-normal ${className}`}>
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
